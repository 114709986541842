import React, { createContext, useState, useContext } from 'react';


const UIContext = createContext(null);

const UIContextProvider = ({ children }) => {
    const [navbarHeight, setNavbarHeight] = useState(0);

    return (
        <UIContext.Provider value={{ navbarHeight, setNavbarHeight }}>
            {children}
        </UIContext.Provider>
    );
};

const useData = () => {
    const context = useContext(UIContext);
    if (context === null) {
        throw new Error('useData must be used within a UIContextProvider');
    }
    return context;
};

export { UIContextProvider, useData };