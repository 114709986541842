
import React, { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import i18n from 'i18next';
import '../../lang/i18n';
import { useTranslation } from 'react-i18next';

import calendarIcon from 'shared-assets/icons/calendar.svg';
import letterIcon from 'shared-assets/icons/letter.svg';
import profileIcon from 'shared-assets/icons/profile.svg';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const FeedbackResultPage = () => {

    const { getAccessTokenSilently } = useAuth0();
    const translation = useTranslation();
    const { t } = translation;
    const currentLang = i18n.language || 'en';

    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();

    const [isFirstRender, setIsFirstRender] = useState(true);
    const [resultsData, setResultsData] = useState([]);
    const [questionnaireData, setQuestionnaireData] = useState({
        structure: [],
        feedback_users: [],
        created: '',
        answered_count: 0,
        origin: {
            type: '',
            title: '',
        },
    });
    const [feedbackData, setFeedbackData] = useState(null);

    const scrollableDivRef = useRef<HTMLDivElement>(null);
    const agreeOptions = [
        t('strongly-disagree'),
        t('disagree'),
        t('neutral'),
        t('agree'),
        t('strongly-agree'),
    ];

    const getResults = async () => {
        try {
            const token = await getAccessTokenSilently();
            const response = await fetch(
                `https://feedback-api.dazzle.zebrain.se/feedback_results/${id}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }

            const results = await response.json();
            console.log('Result data:', results);

            setResultsData(results);
        } catch (error) {
            console.error('Error fetching recipients:', error);
        }
    };

    const getQuestionnaire = async () => {
        try {
            const token = await getAccessTokenSilently();
            const response = await fetch(
                `https://feedback-api.dazzle.zebrain.se/questionnaire/${id}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }

            const questionnaire = await response.json();
            console.log('Questionnaire Data:', questionnaire);

            setQuestionnaireData(questionnaire);
        } catch (error) {
            console.error('Error fetching recipients:', error);
        }
    };

    useEffect(() => {
        getResults();
        getQuestionnaire();
    }, []);

    useEffect(() => {
        if (resultsData.length > 0 && questionnaireData.answered_count) {
            const feedbacks = {
                received: questionnaireData.answered_count,
                total: questionnaireData.feedback_users.length,
            };

            const respondents = questionnaireData.feedback_users;

            const questions = questionnaireData.structure.map((question, index) => {
                const description = question.description;

                // Initialize answer counts
                const answers = [
                    { answer: 0, count: 0 },
                    { answer: 1, count: 0 },
                    { answer: 2, count: 0 },
                    { answer: 3, count: 0 },
                    { answer: 4, count: 0 },
                ];
                console.log('resultsData:', resultsData);
                resultsData = resultsData;
                // Filter resultsData for this question
                const questionResults = resultsData.filter(
                    (result) => result.question_index === index
                );
                console.log('Question results:', questionResults);

                // Aggregate counts
                questionResults.forEach((result) => {
                    const answerIndex = answers.findIndex(
                        (a) => a.answer === result.answer_value
                    );

                    const reversedAnswerIndex = answers.length - 1 - answerIndex;

                    console.log('Answer index:', answerIndex);
                    if (answerIndex !== -1) {
                        answers[reversedAnswerIndex].count += result.num_answers;
                        console.log('Answer count:', answers[answerIndex].count);
                    }
                });
                

                return {
                    description,
                    answers,
                };
            });

            const feedbackData = {
                type: questionnaireData.origin.type,
                title: questionnaireData.origin.title,
                created_at: new Date(questionnaireData.created).toLocaleDateString(currentLang, {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                }),
                feedbacks,
                respondents,
                questions,
            };

            setFeedbackData(feedbackData);
        } else if (questionnaireData.answered_count === 0) {

            console.log('--------Questionnaire data:', questionnaireData);
            const feedbackData = {
                type: questionnaireData.origin.type,
                title: questionnaireData.origin.title,
                created_at: new Date(questionnaireData.created).toLocaleDateString(currentLang, {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                }),
                feedbacks: {
                    received: 0,
                    total: questionnaireData.feedback_users.length,
                },
                respondents: questionnaireData.feedback_users,
                questions: questionnaireData.structure.map((question) => ({
                    description: question.description,
                    answers: [
                        { answer: 0, count: 0 },
                        { answer: 1, count: 0 },
                        { answer: 2, count: 0 },
                        { answer: 3, count: 0 },
                        { answer: 4, count: 0 },
                    ],
                })),
            };

            setFeedbackData(feedbackData);

        }
    }, [resultsData, questionnaireData]);

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        } else {
            getResults();
            getQuestionnaire();
        }
    }, [isFirstRender]);

    if (!feedbackData) {
        return null;
    }

    return (
        <div className="flex flex-col h-full flex-grow min-h-0 gap-6 pr-2">
            <h1 className="text-2xl font-medium text-white">
            {t("your-feedback")}
            </h1>

            {/* Questionnaire info */}
            <div className="flex flex-col gap-3">
                <div className="flex items-start gap-1">
                    <img src={calendarIcon} alt="calendar icon" className="w-6 h-6" />
                    <p className="text-base font-normal text-white">

                        {t("created-at")}: {feedbackData.created_at}
                    </p>
                </div>
                <div className="flex items-start gap-1">
                    <img src={letterIcon} alt="letter icon" className="w-6 h-6" />
                    <p className="text-base font-normal text-white">
                        {t("received")}: {feedbackData.feedbacks.received} {t("feedback-out-of")} {feedbackData.feedbacks.total}
                    </p>
                </div>
                <div className="flex items-start gap-1">
                    <img src={profileIcon} alt="learning icon" className="w-6 h-6" />
                    <p className="text-base font-normal text-white leading-tight">
                        {t("requested-from")}: {feedbackData.respondents.join(", ")}
                    </p>
                </div>
            </div>

            {/* Answers */}
            <div className="">
                {feedbackData.questions.map((question, index) => {
                    // Calculate total responses for this question
                    const totalResponses = question.answers.reduce((sum, answer) => sum + answer.count, 0);

                    return (
                        <React.Fragment key={index}>
                            {/* Title */}
                            <h2 className="text-lg font-medium text-white mb-2">
                                {t("question")} {index + 1}
                            </h2>

                            {/* Description card */}
                            <div className="p-4 bg-neutral-700 text-neutral-400 rounded-md mb-3">
                                {question.description}
                            </div>

                            {/* Answers horizontal bar chart */}
                            <div className="w-full flex flex-col gap-4">
                                {question.answers.map((item, idx) => {
                                    // Calculate percentage for this answer
                                    const percentage = totalResponses > 0 ? (item.count / totalResponses) * 100 : 0;

                                    if(item.count > 0) {
                                        console.log("------item", item);
                                        console.log("------idx", idx);
                                        console.log("------item.answer", item.answer);
                                        console.log("------agreeOptions[item.answer]", agreeOptions[item.answer]);
                                    }


                                    return (
                                        <div key={`answer-${idx}`} className="">
                                            {/* Label and Value Section */}
                                            <div className="flex justify-between items-center mb-2">
                                                <div className="text-white text-sm font-medium">{agreeOptions.toReversed()[item.answer]}</div>
                                                <div className="flex items-center">
                                                    <img src={profileIcon} alt="profile icon" className="w-4 h-4" />
                                                    <span className="ml-2 text-white font-medium">
                                                        {item.count} ({percentage.toFixed(1)}%)
                                                    </span>
                                                </div>
                                            </div>
                                            {/* Bar Section */}
                                            <div className="w-full bg-neutral-600 rounded-full h-2">
                                                <div
                                                    className="bg-primary-500 h-2 rounded-full"
                                                    style={{ width: `${percentage}%` }}
                                                ></div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            {feedbackData.questions.length - 1 !== index && (
                                <hr className="border-neutral-600 my-6" />
                            )}
                        </React.Fragment>
                    );
                })}

            </div>
        </div>
    );
}

export default FeedbackResultPage;
