// ProtectedRoute.tsx
import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAtom } from 'jotai';

import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import { questionnaireAtom } from '../../jotai_state';

import {Tabs} from 'shared-components';
import TaskCard from './components/TaskCard';
import Modal from './components/FeedbackModal';

import { useData } from '../../contexts/dataContext';
import taskIcon from 'shared-assets/icons/task.svg';
import checkmarkIconGreen from 'shared-assets/icons/green/checkmark.svg';

export default function TasksPage() {
  const { getAccessTokenSilently } = useAuth0();
  const prevLocation = useLocation();
  const navigate = useNavigate();

  const translation = useTranslation();
  const { t, i18n } = translation;

  const { tasks } = useData();

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [hasFeedbackHistory, setHasFeedbackHistory] = React.useState(false);
  const [activeTaskId, setActiveTaskId] = React.useState(-1);
  const [activeTaskContent, setActiveTaskContent] = React.useState('');
  const [activeTaskTitle, setActiveTaskTitle] = React.useState('');
  const [questionnaire, setQuestionnaire] = useAtom(questionnaireAtom);

  const onCloseModal = () => {
    setIsModalOpen(false);
  }

  const isDevelopmentPlanRoot = location.pathname === '/tasks';

  const navigateToTaskInChat = (task) => {
    const { name, description, deadline, completed } = task;
    let chatMessage = `${t('auto-task-chat-message')}: ${name}\n\n### ${t('description')}:\n\n${description || ''}`;

    if (deadline) {
      chatMessage += `\n\n**${t('deadline')}**: ${new Date(deadline).toLocaleDateString('sv-SE')}`;
    }

    if (completed) {
      chatMessage += `\n\n**${t('completed')}**: ${t('yes')}`;
    }

    const encodedMessage = encodeURIComponent(chatMessage);
    navigate(`/?message=${encodedMessage}`);
  };

  const navigateToFeedback = (taskId, content, title) => {

    if (hasFeedbackHistory || (questionnaire && questionnaire.structure.length > 0)) {
      if(isModalOpen) {
        setIsModalOpen(false);
      }
      navigate(`/tasks/feedback/create?id=${taskId}&type=task&content=${content}&title=${title}`);
    } else {
      setIsModalOpen(true);
    }
  }

  const getFeedback = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `https://feedback-api.dazzle.zebrain.se/questionnaire`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const results = await response.json();
      console.log('API response:', results);
      setHasFeedbackHistory(results.length > 0);

    } catch (error) {
      setHasFeedbackHistory(false);
    }
  };

  useEffect(() => {
    getFeedback();
  }, []);


  const OngoingTasks = () => {
    return (
      <div className="flex flex-col gap-2 justify-start w-full flex-grow min-h-0 h-full">
        <div>
          <h1 className="text-lg font-medium dark:text-white">
            {t('tasks-page-title')}
          </h1>
        </div>
        <div className="flex flex-col gap-2 pr-2">
          {tasks.filter(task => !task.completed).map((task, index) => (
            <>
              <TaskCard
                key={`task-card-${index}`}
                title={task.name}
                description={task.description || ''}
                leftIcon={taskIcon}
                icon={task.completed ? checkmarkIconGreen : undefined}
                deadline={
                  task.deadline
                    ? `${t('deadline')}: ${new Date(task.deadline).toLocaleDateString('sv-SE')}`
                    : undefined
                }
                onClickChat={() => navigateToTaskInChat(task)}
                onClickFeedback={() => {
                  setActiveTaskContent(task.description || '');
                  setActiveTaskId(task.id);
                  setActiveTaskTitle(task.name);
                  navigateToFeedback(task.id, task.description || '', task.name)

                }}
                taskId={task.id}
              />

            </>
          ))}
        </div>
      </div>
    );
  };

  const CompletedTasks = () => {
    return (
      <div className="flex flex-col gap-2 justify-start w-full flex-grow min-h-0 h-full">
        <div>
          <h1 className="text-lg font-medium dark:text-white">
            {t('completed-tasks')}
          </h1>
        </div>
        <div className="flex flex-col gap-2">
          {tasks.filter(task => task.completed).map((task, index) => (
            <TaskCard
              key={`task-card-${index}`}
              title={task.name}
              description={task.description || ''}
              leftIcon={taskIcon}
              icon={task.completed ? checkmarkIconGreen : undefined}
              deadline={
                task.deadline
                  ? `${t('deadline')}: ${new Date(task.deadline).toLocaleDateString('sv-SE')}`
                  : undefined
              }

              onClickFeedback={() => {
                setActiveTaskContent(task.description || '');
                setActiveTaskId(task.id);
                navigateToFeedback(task.id, task.description || '', task.name)
                setActiveTaskTitle(task.name);
              }}

            />
          ))}
        </div>
      </div>
    );
  };

  if (!isDevelopmentPlanRoot) {
    return <Outlet />
  }

  if (!tasks || !tasks.length) {
    return (
      <div className="w-full h-full flex justify-center mt-4 desktop:mt-6">
        <div className="flex flex-col gap-2 justify-start align-middle w-full desktop:w-151 m-4 mt-0">
          <div className="flex justify-center">
            <img src={taskIcon} alt="task icon" className="w-6 h-6 desktop:w-10 desktop:h-10" />
          </div>
          <p className="text-base font-normal text-center">
            {t('no-tasks')}
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <h1 className="text-2xl font-medium dark:text-white my-4 desktop:my-6">
        {t("tasks-nav-title")}
      </h1>
      <div className="flex flex-col flex-grow min-h-0">
        <Tabs
          items={[
            { title: t('ongoing'), content: <OngoingTasks /> },
            { title: t('completed'), content: <CompletedTasks /> },
          ]}
        />
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        onModalStartClick={() => {
          if(isModalOpen) {
            setIsModalOpen(false);
          }
          navigate(`/tasks/feedback/create?id=${activeTaskId}&type=task&content=${activeTaskContent}&title=${activeTaskTitle}`)
        }}
      />
    </>
  );
}