import React, { createContext, useContext, useState, useEffect } from "react";
import { atom, useAtom } from 'jotai';
import { initializeApp, FirebaseApp } from "firebase/app";
import {
  getAuth,
  Auth,
  UserCredential,
  signInWithCustomToken
} from "firebase/auth";
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from "@sentry/react";

export interface IUser {
  email: string;
  uid: string;
  displayName: string;
  photoURL: string;
}


const firebaseConfig = {
  apiKey: "AIzaSyBwYtmQG33PnzAFxpplqpBpGye11ttFBDc",
  authDomain: "zebrain.firebaseapp.com",
  projectId: "zebrain",
  storageBucket: "zebrain.appspot.com",
  messagingSenderId: "337432544873",
  appId: "1:337432544873:web:32dabdb379af35ced284f8"
};


const AuthContext = createContext(null) as any;
const AuthContextProvider = ({ children }) => {
  // const [firebaseUser, setFirebaseUser] = useState(null);
  const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, logout: auth0logout } = useAuth0();

  const [platformUserId, setPlatformUserId] = useState(null);

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const fetchUserData = async (auth0Token) => {
    try {
      const response = await fetch('https://api.dazzle.zebrain.se/user', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${auth0Token}`
        }
      });

      if (!response.ok) {
        setPlatformUserId(null);
        throw new Error('Failed to fetch user data');
      }

      const userData = await response.json();
      console.log("User data:", userData);
      Sentry.setUser({
        id: userData.id
      });
      setPlatformUserId(userData.id);
      return userData;
    } catch (error) {
      setPlatformUserId(null);
      console.error('Error fetching user data:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        try {
          loginToFirebase();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // if (firebaseUser) {
        // logoutOfFirebase();
        // }
      }
    }

  }, [isAuthenticated, isLoading]);




  const loginToAuth0 = async () => {
    // try {
    throw new Error("Not implemented");
    //   console.log("Login with redirect")
    //   console.log("window.location.pathname", window.location.pathname);
    //   console.log("window.location.origin", window.location.origin);
    //   await loginWithRedirect({
    //     appState: { targetUrl: window.location.pathname, returnTo: window.location.origin }
    //   });
    // } catch (error) {
    //   throw error;
    // }
  };

  // LOGIN
  const loginToBackend = async () => {
    let token = await getAccessTokenSilently();
    fetchUserData(token);
    // console.log("token", token);
    try {
      const response = await fetch('https://api.dazzle.zebrain.se/fs_token', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const customToken = await response.json();
      return customToken;
    } catch (error) {
      throw error;
    }
  };

  const loginToFirebase = async () => {
    try {
      const customToken = await loginToBackend();

      const userCredential = await signInWithCustomToken(auth, customToken)

      return userCredential;
    } catch (error) {
      throw error;
    }
  }

  // const login = async () => {
  //   try {
  //     await loginToAuth0();
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // SIGNUP
  const signupToBackend = async (invite_token) => {

    let token = await getAccessTokenSilently();
    try {
      const response = await fetch('https://api.dazzle.zebrain.se/signup/', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ token: invite_token })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }


    } catch (error) {
      throw error;
    }
  }

  const signup = async (invite_token) => {
    try {
      await loginToAuth0();
      try {
        await signupToBackend(invite_token);
      } catch (error) {
        console.log("error", error);
      }
    } catch (error) {
      throw error;
    }
  }

  // LOGOUT
  const logoutOfFirebase = async () => {
    try {
      console.log("Sign out")
      await auth.signOut();
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      console.log("Sign out")
      await auth0logout();
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        logout,
        // login,
        signup,
        user,
        platformUserId
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthConsumer = AuthContext.Consumer;


export { AuthContextProvider, AuthConsumer, AuthContext };