import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const AcceptInvitePage = () => {
    const { code } = useParams(); // Extract invite code from URL
    const navigate = useNavigate(); // Hook to navigate programmatically
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    const { platformUserId } = useContext(AuthContext) as any;

    console.log("AcceptInvitePage");

    useEffect(() => {
        console.log("platformUserId", platformUserId);
        if (!platformUserId) {
            return;
        }
        console.log("AcceptInvitePage useEffect");
        const acceptInvite = async () => {
            if (!isAuthenticated) {
                return;
            }
            try {
                let token = await getAccessTokenSilently();

                const response = await fetch("https://api.dazzle.zebrain.se/accept_invite", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Ensure auth0Token is defined and available
                    },
                    body: JSON.stringify({ code: code }) // If your backend expects additional data
                });

                if (!response.ok) {
                    if (response.status === 404) {
                        throw new Error('Invite code is invalid or has expired. Please check your code and try again.');
                    }
                    throw new Error(`Error: ${response.statusText}`);
                }

                const data = await response.json();
                console.log('Invite accepted:', data);
                // Handle the response data as needed

                // Redirect to the root page after successful invite acceptance (delay 2 seconds)
                setTimeout(() => navigate('/?signup=true'), 2000);
            } catch (err) {
                console.error('Failed to accept invite:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        acceptInvite();
    }, [platformUserId, code, navigate, isAuthenticated, getAccessTokenSilently]); // Dependency array ensures this runs when code changes

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return <div>Invite accepted successfully!</div>; // This message will appear briefly before redirecting
};

export default AcceptInvitePage;