import React from 'react';
import { CustomCard as Card, CardHeader, CardBody } from 'shared-components'

import { useNavigate } from 'react-router-dom';

import '../../../../lang/i18n';
import { useTranslation } from 'react-i18next';

const emmaAvatar = new URL('/public/emma-avatar.png', import.meta.url).toString();

interface DevelopmentCardProps {
    title: string;
    description: string;
    onClick: () => void;
    leftIcon?: string;
}

const DevelopmentCard = ({
    title,
    description,
    onClick,
    leftIcon,
}: DevelopmentCardProps) => {

    const translation = useTranslation();
    const { t, i18n } = translation;

    return (
        <Card onClick={onClick}>
            <CardHeader className="border-neutral-600 border-b flex items-center px-4 py-3 bg-neutral-700 rounded-t-md">
                {leftIcon && (
                    <img src={leftIcon} alt="icon" className="w-6 h-6 mr-1.5" />
                )}
                <h3 className="text-base font-medium text-gray-800 dark:text-white flex-grow">
                    {title}
                </h3>
            </CardHeader>
            <CardBody className="p-4">
                <p className="text-base font-normal text-gray-800 dark:text-neutral-300 line-clamp-3">
                    {description}
                </p>
            </CardBody>
        </Card>
    );
};

export default DevelopmentCard;