// Breadcrumbs.js

import React from 'react';
import { useLocation, matchPath, Link, useParams, generatePath } from 'react-router-dom';
import chevronRightIcon from 'shared-assets/icons/chevronRight.svg';
import { useTranslation } from 'react-i18next';

const Breadcrumbs = ({ breadcrumbConfig }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const { pathname } = location;

  const breadcrumbs = breadcrumbConfig
    .filter(({ path }) => matchPath({ path, end: false }, pathname))
    .map(({ path, breadcrumbKey }) => ({
      path: generatePath(path, params),
      breadcrumb: t(breadcrumbKey),
    }));

  if (breadcrumbs.length < 3) {
    return null;
  }

  return (
    <nav aria-label="breadcrumb" className="w-full my-4 desktop:my-6 justify-center">
      <div className="flex flex-col justify-start align-middle w-full desktop:w-151">
        <ol className="flex flex-wrap items-center text-neutral-400 text-xs">
          {breadcrumbs.map((crumb, index) => (
            <li key={index} className="flex items-center">
              <Link to={crumb.path} className="hover:text-primary-500">
                {crumb.breadcrumb}
              </Link>
              {index < breadcrumbs.length - 1 && (
                <img
                  src={chevronRightIcon}
                  alt="Chevron Right"
                  className="w-4 h-4 mx-1.5"
                />
              )}
            </li>
          ))}
        </ol>
      </div>
    </nav>
  );
};

export default Breadcrumbs;
