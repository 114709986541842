// AppRouter.js

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AuthPage from "./pages/AuthPage";
import ChatView from "./pages/ChatView";
import Home from "./pages/Home";
import ProtectedPage from "./pages/ProtectedPage";
import TasksPage from "./pages/TasksPage";
import DevelopmentAreaPage from "./pages/DevelopmentAreaPage";
import FocusAreaPage from "./pages/FocusAreaPage";
import { App } from "./App";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import AcceptInvite from "./pages/AcceptInvitePage";
import SupportPage from "./pages/SupportPage";
import AboutPage from "./pages/AboutPage";
import CalendarPage from "./pages/CalendarPage";
import FeedbackPage from "./pages/FeedbackPage";
import CreateFeedbackPage from "./pages/CreateFeedbackPage";
import CreateFeedbackSummaryPage from "./pages/CreateFeedbackSummaryPage";
import FeedbackRecipientsPage from "./pages/FeedbackRecipientsPage";
import FeedbackResultPage from "./pages/FeedbackResultPage";
import ReceivedFeedbackPage from "./pages/ReceivedFeedbackPage";
import FeedbackRespondentPage from "./pages/FeedbackRespondentPage";
import TokenPage from "./pages/TokenPage";
import { useTranslation } from 'react-i18next';

const AppRouter = ({ outerRoute }) => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth0();

  const { t } = useTranslation();

  // Define routes without handle properties
  const routes = [
    {
      path: '/',
      element: <ChatView />,
    },
    {
      path: '/tasks',
      element: <TasksPage />,
    },
    {
      path: '/tasks/feedback/create',
      element: <CreateFeedbackPage />,
    },
    {
      path: '/tasks/feedback/create/recipients',
      element: <FeedbackRecipientsPage />,
    },
    {
      path: '/tasks/feedback/create/recipients/summary',
      element: <CreateFeedbackSummaryPage />,
    },
    {
      path: '/accept_invite/:code',
      element: <AcceptInvite />,
    },
    {
      path: '/development-plan',
      element: <DevelopmentAreaPage />,
    },
    {
      path: '/development-plan/focus-area/:id',
      element: <FocusAreaPage />,
    },
    {
      path: '/feedback/questionnaire/:id',
      element: <FeedbackRespondentPage />,
    },
    {
      path: '/feedback',
      element: <FeedbackPage />,
    },
    {
      path: '/feedback/result/:id',
      element: <FeedbackResultPage />,
    },
    {
      path: '/about',
      element: <AboutPage />,
    },
    {
      path: '/support',
      element: <SupportPage />,
    },
    {
      path: '/calendar',
      element: <CalendarPage />,
    },
    {
      path: '/token',
      element: <TokenPage />,
    }
    // Add more routes as needed
  ];

  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      children: routes,
    },
  ]);

  useEffect(() => {
    if (outerRoute) {
      router.navigate(outerRoute);
    }
  }, [outerRoute]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const currentPath = window.location.pathname;
      const isAcceptInvite = currentPath.startsWith('/accept_invite/');
      const isFeedbackQuestionnaire = currentPath.startsWith('/feedback/questionnaire/');

      if (isFeedbackQuestionnaire) {
        return;
      }

      const loginOptions = {
        appState: {
          returnTo: currentPath,
        },
      };

      if (isAcceptInvite) {
        loginOptions.authorizationParams = { screen_hint: "signup" };
      }

      loginWithRedirect(loginOptions);
    }
  }, [isLoading, isAuthenticated]);

  return <RouterProvider router={router} />;
};

export default AppRouter;
