// ModalHeader.tsx
import React, { useContext } from 'react';
import { ModalVariantContext } from '../../../../contexts/ModalContext';

interface ModalHeaderProps {
  children: React.ReactNode;
  onClose: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ children, onClose }) => {
  const variant = useContext(ModalVariantContext);

  const variantClasses = {
    neutral: {
      border: 'border-neutral-600',
      "text": 'text-white',
    },
    warning: {
      border: 'border-cherry-400',
      text: 'text-neutral-900',
    },
  };

  return (
    <div
      className={`flex justify-between items-center py-3 px-4 border-b ${variantClasses[variant].border} ${variantClasses[variant].text}`}
    >
      {children}
      <button
        onClick={onClose}
        className=" rounded-full p-1 focus:outline-none"
      >
        <span className="sr-only">Close</span>
        <svg
          className="w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            d="M18 6L6 18"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 6l12 12"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
};

export default ModalHeader;
