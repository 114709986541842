import React, { createContext, useState, useContext, useEffect } from 'react';

const NotificationContext = createContext(null) as any;

const Notification = ({ message, image,  onClose }) => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        onClose();
      }, 5000);
    }, 8000);

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);
  
  const darkStyle = 'dark:bg-neutral-800 dark:border-neutral-700 dark:text-white';
  const fadeOutStyle = fadeOut ? 'opacity-0 translate-y-4' : 'opacity-100 translate-y-0';
  const animationStyle = 'transition-all duration-5000';
  const shapeStyle = 'max-w-md bg-white border border-gray-200 rounded-md shadow-lg';
  const stylingClasses = `${shapeStyle} ${darkStyle} ${animationStyle} ${fadeOutStyle}`;

  return (
    <div className={stylingClasses}>
      <div className="flex items-center p-4 gap-2.5">
        <div className="flex-shrink-0">
          <img className="h-6 w-6" src={image} alt="" />
        </div>
        <div>
          <h3 className="text-base font-medium text-gray-800 dark:text-white">{message}</h3>
        </div>
      </div>
    </div>
  );
};

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (message, image) => {
    setNotifications((prevNotifications) => [...prevNotifications, { message, image }]);
  };

  const removeNotification = (index) => {
    setNotifications((prevNotifications) => prevNotifications.filter((_, i) => i !== index));
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}
      <div className="fixed inset-0 flex justify-center items-start p-4 pointer-events-none">
        {notifications.map((notification, index) => (
          <Notification
            key={index}
            message={notification.message}
            image={notification.image}
            onClose={() => removeNotification(index)}
          />
        ))}
      </div>
    </NotificationContext.Provider>
  );
};

const useNotification = (): any => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

export { NotificationProvider, useNotification };