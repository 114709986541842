

import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, CustomButton as Button} from 'shared-components';

import '../../../../lang/i18n';
import { useTranslation } from 'react-i18next';

const FeedbackModal = ({ isOpen, onClose }) => {

    const translation = useTranslation();
    const { t } = translation;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader onClose={onClose}>
                <h3 className="text-lg text-white font-medium">
                    {t("feedback-request-sent")}
                </h3>
            </ModalHeader>
            <ModalBody>
                <p className="text-medium text-base">
                    {t('feedback-request-sent-description')}
                </p>
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose}>
                    {t('back-to-tasks')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default FeedbackModal;