import { useTranslation } from "react-i18next";

const TimeAgo: React.FC<{ date: Date }> = ({ date }) => {
    const { i18n, t } = useTranslation();
    const rtf1 = new (Intl as any).RelativeTimeFormat(i18n.language, { style: 'short', numeric: 'auto' });

    let diff = (date.getTime() - Date.now()) / 1000;

    const DIVISIONS = [
        { amount: 60, name: "seconds" },
        { amount: 60, name: "minutes" },
        { amount: 24, name: "hours" },
        { amount: 7, name: "days" },
        { amount: 4.34524, name: "weeks" },
        { amount: 12, name: "months" },
        { amount: Number.POSITIVE_INFINITY, name: "years" },
    ]
    const getParams = (diff) => {
        let i = 0;
        let abs_diff = Math.abs(diff);
        let sign = diff < 0 ? -1 : 1;
        let segment = abs_diff;
        while (segment >= DIVISIONS[i].amount) {
            segment /= DIVISIONS[i].amount;
            i++;
        }

        return [(segment * sign) | 0, DIVISIONS[i].name];
    }

    let params = getParams(diff);

    return (<span>
        {(diff < 10 && diff > -10) ? t("time-ago-now") : rtf1.format(...params)}
    </span>);
}

export { TimeAgo };