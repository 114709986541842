import React, { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../../lang/i18n';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';


import { FeedbackCard} from 'shared-components'

import calendarIcon from 'shared-assets/icons/calendar.svg';
import taskIcon from 'shared-assets/icons/task.svg';
import learningIcon from 'shared-assets/icons/learning.svg';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const FeedbackPage = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { t } = useTranslation();
    const currentLang = i18n.language || 'en';
    const location = useLocation();
    const navigate = useNavigate();

    const [responses, setResponses] = useState([]);

    const scrollableDivRef = useRef<HTMLDivElement>(null);

    const getFeedback = async () => {
        try {
            const token = await getAccessTokenSilently();
            const response = await fetch(
                `https://feedback-api.dazzle.zebrain.se/questionnaire`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }

            const results = await response.json();
            console.log('API response:', results);

            // Process the results to group by origin.id
            const groupedResponses = results.reduce((groups, questionnaire) => {
                const originId = questionnaire.origin.id;
                if (!groups[originId]) {
                    groups[originId] = {
                        id: originId,
                        title: questionnaire.origin.title,
                        type: questionnaire.origin.type,
                        results: []
                    };
                }
                groups[originId].results.push({
                    id: questionnaire.id,
                    title: new Date(questionnaire.created).toLocaleDateString(currentLang, {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                    }),
                    icon: calendarIcon
                });
                return groups;
            }, {});

            const groupedResponsesArray = Object.values(groupedResponses);

            setResponses(groupedResponsesArray);

        } catch (error) {
            console.error('Error fetching recipients:', error);
        }
    };

    useEffect(() => {
        getFeedback();
    }, []);

    return (
        <div className="flex flex-col h-full flex-grow min-h-0 gap-6 mt-6">
            <h1 className="text-2xl font-medium text-white">
                {t("feedback-title")}
            </h1>

            {/* Question cards */}
            <div ref={scrollableDivRef} className="flex-1 flex flex-col pb-1 pr-2 gap-6">
                {responses.map((response, index) => (
                    <FeedbackCard
                        key={index}
                        title={response.title}
                        items={response.results.map((result) => ({
                            title: result.title,
                            icon: result.icon,
                            onClick: () => navigate(`/feedback/result/${result.id}`)
                        }))}
                        leftIcon={
                            response.type === "task" ? taskIcon : learningIcon
                        }
                    />
                ))}
            </div>
        </div>
    );
};

export default FeedbackPage;
