import React from 'react'
import {Modal, ModalHeader, ModalBody} from 'shared-components';

import '../../../../lang/i18n';
import { useTranslation } from 'react-i18next';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const SupportModal = (props: ModalProps) => {

    const { isOpen, onClose } = props;

    const translation = useTranslation();
    const { t, i18n } = translation;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader onClose={onClose}>
                <h3 className="text-lg text-white font-medium">{t('support-modal-success-title')} </h3>
            </ModalHeader>
            <ModalBody>
                <p>{t('support-modal-success-description')}</p>
            </ModalBody>
        </Modal>
    );

}

export default SupportModal