// ModalBody.tsx
import React, { useContext } from 'react';
import { ModalVariantContext } from '../../../../contexts/ModalContext';

interface ModalBodyProps {
  children: React.ReactNode;
}

const ModalBody: React.FC<ModalBodyProps> = ({ children }) => {
  const variant = useContext(ModalVariantContext);

  const variantClasses = {
    neutral: {
      text: 'text-neutral-300',
    },
    warning: {
      text: 'text-neutral-900',
    },
  };

  return (
    <div className={`p-4 overflow-y-auto ${variantClasses[variant].text}`}>
      {children}
    </div>
  );
};

export default ModalBody;
