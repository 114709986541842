// ModalFooter.tsx
import React, { useContext } from 'react';
import { ModalVariantContext } from '../../../../contexts/ModalContext';

interface ModalFooterProps {
  children: React.ReactNode;
}

const ModalFooter: React.FC<ModalFooterProps> = ({ children }) => {
  const variant = useContext(ModalVariantContext);

  const variantClasses = {
    neutral: {
      border: 'border-neutral-600',
      text: 'text-white',
    },
    warning: {
      border: 'border-cherry-400',
      text: 'text-neutral-900',
    },
  };

  return (
    <div
      className={`flex justify-center items-center gap-x-2 py-3 px-4 border-t ${variantClasses[variant].border} ${variantClasses[variant].text}`}
    >
      {children}
    </div>
  );
};

export default ModalFooter;
