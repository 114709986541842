
// CardHeader.tsx
import React from 'react';

interface CardHeaderProps {
  children: React.ReactNode;
  className?: string;
}

const CardHeader = ({ children, className = '' }: CardHeaderProps) => (
  <div className={`border-neutral-600 border-b rounded-t-md py-3 px-4 ${className}`}>
    {children}
  </div>
);

export default CardHeader;
