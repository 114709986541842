import { collection, doc, onSnapshot, orderBy, query, getDocs } from 'firebase/firestore';
import { atom, useAtom } from 'jotai';
import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { DB } from '../App';
import { useNotification } from './notificationContext';
import { AuthContext } from './AuthContext';
import * as Sentry from "@sentry/react";

// TODO: fix these errors please
import taskIconGreen from 'shared-assets/icons/green/task.svg';
import checkmarkIconGreen from 'shared-assets/icons/green/checkmark.svg';

const DataContext = createContext(null) as any;
const messagesAtom = atom<Message[]>([]);
const pendingMessagesAtom = atom<Message[]>([]);
const tasksAtom = atom<Task[]>([]);
const awaitAIAtom = atom<boolean>(false);
const developmentAreasAtom = atom<FocusArea[]>([]);

const ClientDataContextProvider = ({ children }) => {
  const isInitialDataLoaded = useRef(false);
  const [messages, setMessages] = useAtom(messagesAtom);
  const [pendingMessages, setPendingMessages] = useAtom(pendingMessagesAtom);
  const [tasks, setTasks] = useAtom(tasksAtom);
  const previousTasksRef = useRef<Task[]>([]);
  const [awaitAI, setAwaitAI] = useAtom(awaitAIAtom);
  const [developmentAreas, setDevelopmentAreas] = useAtom(developmentAreasAtom);

  const { addNotification } = useNotification();

  let auth = useContext(AuthContext) as any;
  const { user } = auth;
  const [client_id, setClientId] = useState<string | null>(null)

  const [glance, setGlance] = useState(null);

  const retryWithBackoff = (fn, retries = 5, delay = 1000) => {
    return new Promise((resolve, reject) => {
      fn().then(resolve).catch((err) => {
        if (retries === 0) {
          reject(err);
        } else {
          setTimeout(() => {
            retryWithBackoff(fn, retries - 1, delay * 2).then(resolve).catch(reject);
          }, delay);
        }
      });
    });
  };

  const listenWithRetry = (queryFn, callback) => {
    return retryWithBackoff(() => new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(queryFn, callback, (error) => {
        if (error.code === 'permission-denied') {
          reject(error);
        } else {
          resolve(unsubscribe);
        }
      });
    }));
  };

  useEffect(() => {
    console.log('-- tasks --', tasks.length);
  }, [tasks]);

  useEffect(() => {
    if (!user || !user.sub) {
      return;
    }
    const remote_user_id = user.sub;

    const path = doc(DB, `group/test9/remote_users/${remote_user_id}`);
    listenWithRetry(path, (doc) => {
      console.log("glance: ", doc.data());
      setGlance(doc.data());
    }).catch((error) => {
      console.error('Failed to set up listener for glance:', error);
    });

  }, [user]);

  useEffect(() => {
    if (!glance) {
      return;
    }
    if (glance.user_client_info.clients.length === 0) {
      return;
    }
    let client = glance.user_client_info.clients[0];
    setClientId(client.id);
    Sentry.setContext('client', { id: client.id, customer_id: client.customer_id });
  }, [glance]);

  useEffect(() => {
    if (!client_id) {
      return;
    }

    const messagesQuery = query(collection(DB, `group/test9/clients/${client_id}/messages`), orderBy('ts'));
    listenWithRetry(messagesQuery, (snapshot) => {
      setMessages(snapshot.docs.map((doc) => fromMessageDto(doc.data() as MessageDto)));
      let changes = snapshot.docChanges().length;
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          if (changes === 1) {
            const data = change.doc.data();
            if (data.author === 'assistant') {
              setAwaitAI(false);
              setPendingMessages([]);
            }
          }
        }
      });
    }).catch((error) => {
      console.error('Failed to set up listener for messages:', error);
    });

  }, [client_id]);


  
  useEffect(() => {
    if (!client_id) {
      return;
    }
  
    const tasksQuery = query(
      collection(DB, `group/test9/clients/${client_id}/tasks`),
      orderBy('deadline')
    );
  
    listenWithRetry(tasksQuery, (snapshot) => {
      const newTasks = snapshot.docs.map((doc) =>
        fromTaskDto(doc.data() as TaskDto)
      );
  
      setTasks(newTasks);
  
      if (!isInitialDataLoaded.current) {
        // Skip processing on initial load
        isInitialDataLoaded.current = true;
        previousTasksRef.current = newTasks;
        return;
      }
  
      snapshot.docChanges().forEach((change) => {
        const changeData = change.doc.data() as TaskDto;
  
        if (change.type === 'added') {
          addNotification('New task created', taskIconGreen);
        }
  
        if (change.type === 'modified') {
          const previousTask = previousTasksRef.current.find(
            (task) => task.id === changeData.id
          );
          if (previousTask && !previousTask.completed && changeData.completed) {
            addNotification('Task completed', checkmarkIconGreen);
          }
        }
      });
  
      previousTasksRef.current = newTasks;
    }).catch((error) => {
      console.error('Failed to set up listener for tasks:', error);
    });
  }, [client_id]);
  

  const fetchDevelopmentAreas = async () => {
    const path = `group/test9/clients/${client_id}/focus_areas`;
    if (!client_id) return;

    const querySnapshot = await getDocs(collection(DB, path));
    let docsData = querySnapshot.docs.map(doc => doc.data());
    setDevelopmentAreas(docsData as any);
  }

  return (
    <DataContext.Provider value={{
      messages,
      tasks,
      pendingMessages,
      setPendingMessages,
      awaitAI,
      setAwaitAI,
      client_id,
      developmentAreas,
      setDevelopmentAreas,
      fetchDevelopmentAreas,
      addNotification,
      glance
    }}>
      {children}
    </DataContext.Provider>
  );
};

const useData = (): any => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

export { ClientDataContextProvider, useData };

type Message = {
  id: string;
  ts: Date;
  text: string;
  sender: 'user' | 'assistant' | 'system' | 'function';
};
type MessageDto = {
  id: string;
  client_id: string;
  author: string;
  ts: number;
  message: string;
};
const fromMessageDto = (data: MessageDto) => {
  const message: Message = {
    id: data.id,
    ts: new Date(data.ts),
    text: data.message,
    sender: data.author as any
  };
  return message;
}
type Task = {
  id: string;
  deadline: Date;
  name: string;
  description?: string;
  completed?: boolean;
};
type TaskDto = {
  id: string;
  deadline: number;
  name: string;
  description?: string;
  completed?: boolean;
};
const fromTaskDto = (data: TaskDto) => {
  const task: Task = {
    id: data.id,
    deadline: data.deadline ? new Date(data.deadline) : null,
    name: data.name,
    completed: data.completed,
    description: data.description
  };
  return task;
}
type FocusArea = {
  title: string;
  description: string;
  id: string;
}