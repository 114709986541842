import React, { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

const TokenPage = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        const getToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                setToken(accessToken);
            } catch (e) {
                console.error(e);
            }
        };

        getToken();
    }, []);

    return <div className="text-white max-w-full overflow-visible break-words whitespace-normal">{token}</div>;

}



export default TokenPage;