// FeedbackRecipientsPage.tsx

import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { questionnaireAtom } from '../../jotai_state';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';


import closeIcon from 'shared-assets/icons/close.svg';
import alertIcon from 'shared-assets/icons/red/alert.svg';

import {CustomButton, Input, Badge, Checkbox} from 'shared-components';

const FeedbackRecipientsPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const [questionnaire, setQuestionnaire] = useAtom(questionnaireAtom);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [existingContacts, setExistingContacts] = useState<{ name: string; email: string }[]>([
  ]);
  const [newRecipients, setNewRecipients] = useState<
    { name: string; email: string; isValid: boolean }[]
  >([]);
  const [showRecipientAlert, setShowRecipientAlert] = useState(false);
  const [showQuestionsAlert, setShowQuestionsAlert] = useState(false);

  // State for search term
  const [searchTerm, setSearchTerm] = useState('');



  const removeRecipient = (email) => {
    console.log('Removing recipient:', email);
    setQuestionnaire({
        ...questionnaire,
        recipients: questionnaire.recipients.filter((recipient) => recipient.email !== email),
    });
  }

  const getRecipients = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        'https://feedback-api.dazzle.zebrain.se/feedback_users',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const questionData = await response.json();
      console.log('API response:', questionData);

      setExistingContacts(
        questionData.map((contact: any) => ({
          name: contact.name,
          email: contact.id,
        }))
      );
    } catch (error) {
      console.error('Error fetching recipients:', error);
    }
  };

  useEffect(() => {
    getRecipients();
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    } else {
      if (questionnaire.structure.length === 0) {
        console.log('No questions found, redirecting to create page');
        navigate(`/tasks/feedback/create`);
      }
    }
  }, [isFirstRender]);


  useEffect(() => {
    const nonExistingRecipients = questionnaire.recipients.filter(
      (recipient) =>
        !existingContacts.some((contact) => contact.email === recipient.email)
    );

    setNewRecipients(
      nonExistingRecipients.map((recipient) => ({
        ...recipient,
        isValid: true,
      }))
    );
  }, [existingContacts, questionnaire.recipients]);

  useEffect(() => {
    if (questionnaire.recipients.length > 0 && showRecipientAlert) {
      setShowRecipientAlert(false);
    }
  }, [questionnaire.recipients.length, showRecipientAlert]);

  useEffect(() => {
    if (questionnaire.structure.length > 0 && showQuestionsAlert) {
      setShowQuestionsAlert(false);
    }
  }, [questionnaire.structure.length, showQuestionsAlert]);


  const ShowNewRecipientInput = () => {
    setNewRecipients((prev) => [
      ...prev,
      {
        name: '',
        email: '',
        isValid: false,
      },
    ]);
  };

  const goToNextPage = () => {
      setShowRecipientAlert(questionnaire.recipients.length === 0);
      setShowQuestionsAlert(questionnaire.structure.length === 0);
      if(questionnaire.recipients.length === 0 || questionnaire.structure.length === 0) {
        return
      }
      navigate(`/tasks/feedback/create/recipients/summary`);
  };

  const emailValidator = (text: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return {
      isValid: emailRegex.test(text),
      error: t('invalid-email'),
    };
  };

  // Separate selected and available contacts
  const selectedContacts = existingContacts.filter((contact) =>
    questionnaire.recipients.some((recipient) => recipient.email === contact.email)
  );

  const availableContacts = existingContacts.filter((contact) =>
    !questionnaire.recipients.some((recipient) => recipient.email === contact.email)
  );

  // Apply search filter to available contacts
  const filteredAvailableContacts = availableContacts.filter((contact) =>
    contact.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Determine if all available contacts are selected
  const allAvailableSelected = availableContacts.length > 0 && availableContacts.every((contact) =>
    questionnaire.recipients.some((recipient) => recipient.email === contact.email)
  );

  return (
    <div className="flex flex-col h-full flex-grow min-h-0 gap-6">
      <h1 className="text-xl font-medium dark:text-white">
        {t('feedback-select-recipients')}
      </h1>

      {/* Selected Recipients Section */}
      <div className="">
        <h2 className="text-lg font-medium dark:text-white mb-2">
          {t('selected')}
        </h2>
        {selectedContacts.length === 0 ? (
          <p className="text-sm font-medium text-neutral-400 mb-6">
            {t('no-selected-recipients')}
          </p>
        ) : (
          <div className="flex flex-row gap-2 flex-wrap mb-6">
            {selectedContacts.map((contact) => (
              <div key={contact.email} className="flex items-center">
                <Badge
                  key={`selected-${contact.email}`}
                  rightIcon={closeIcon}
                  onRightIconClick={() => removeRecipient(contact.email)}
                  label={`${contact.name} (${contact.email})`}
                  size="sm"
                />
              </div>
            ))}
          </div>
        )}
        {/* Search Input Field */}
        <div className="px-1">
          <Input
            label={t('search-email')}
            value={searchTerm}
            placeholder={t('search-email')}
            onChange={(value) => setSearchTerm(value)}
            leftIcon={
              <svg
                className="w-5 h-5 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            }
          />
        </div>
      </div>

      {/* Available Recipients Section */}
      <div className="gap-2 flex flex-col pb-1 px-[1px]">
        {/* <div className="flex items-center mb-2">
          <Checkbox
            id="selectAllAvailable"
            label={t('select-all-available')}
            checked={allAvailableSelected}
            onChange={(checked) => {
              if (checked) {
                // Select all available contacts
                setQuestionnaire((prev) => ({
                  ...prev,
                  recipients: [
                    ...prev.recipients,
                    ...availableContacts.filter(
                      (contact) =>
                        !prev.recipients.some(
                          (recipient) => recipient.email === contact.email
                        )
                    ),
                  ],
                }));
              } else {
                // Deselect all available contacts
                setQuestionnaire((prev) => ({
                  ...prev,
                  recipients: prev.recipients.filter(
                    (recipient) =>
                      !availableContacts.some(
                        (contact) => contact.email === recipient.email
                      )
                  ),
                }));
              }
            }}
          />
        </div> */}
        <h2 className="text-lg font-medium dark:text-white">
          {t('all')}
        </h2>

        {filteredAvailableContacts.length > 0 ? (
          filteredAvailableContacts.map((contact) => (
            <div key={contact.email} className="flex items-center">
              <Checkbox
                id={`available-${contact.email}`}
                label={`${contact.name} (${contact.email})`}
                checked={questionnaire.recipients.some(
                  (recipient) => recipient.email === contact.email
                )}
                onChange={(checked) => {
                  if (checked) {
                    // Select recipient
                    setQuestionnaire((prev) => ({
                      ...prev,
                      recipients: [...prev.recipients, contact],
                    }));
                  } else {
                    // Deselect recipient
                    setQuestionnaire((prev) => ({
                      ...prev,
                      recipients: prev.recipients.filter(
                        (recipient) => recipient.email !== contact.email
                      ),
                    }));
                  }
                }}
              />
            </div>
          ))
        ) : (
          <p className="text-gray-500 italic">{t('no-available-recipients')}</p>
        )}
      </div>

      {showRecipientAlert && !showQuestionsAlert && (
        <div className="flex items-center rounded-md border border-cherry-500 bg-cherry-200 text-neutral-900 p-4 gap-2">
          <img src={alertIcon} alt="Alert" className="w-6 h-6" />
          <span>{t('feedback-recipients-alert')}</span>
        </div>
      )}

      {showQuestionsAlert && (
        <div className="flex items-center rounded-md border border-cherry-500 bg-cherry-200 text-neutral-900 p-4 gap-2">
          <img src={alertIcon} alt="Alert" className="w-6 h-6" />
          <span>{t('feedback-recipientpage-questions-alert')}</span>
        </div>
      )}

      {/* Action Buttons */}
      <div className="flex flex-col gap-2">


        <CustomButton
          onClick={goToNextPage}
          extraStyle="w-full"
          extraProps={{ disabled: false }}
        >
          {t('next')}
        </CustomButton>
      </div>
    </div>
  );
};

export default FeedbackRecipientsPage;
