import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                "home": "Home",
                "all": "All",
                "start": "Start",
                "invalid-email": "Invalid email address. Please enter a correct email address",
                "next": "Next",
                'deadline': 'Deadline',
                "continue": "Continue",
                "time-ago-now": "now",
                "send": "Send",
                "submit": "Submit",
                "search-email": "Search email",
                "chat-placeholder": "Type your message here...",
                "chat-send": "Send",
                "chat-start": "Get started",
                "chat": "Chat",
                "menu-chat": "Chat with Emma",
                "menu-my-tasks": "My tasks",
                "menu-development-plan": "My development plan",
                "menu-my-account": "My account",
                "menu-support": "Support",
                "menu-about-company": "About Zebrain",
                "task": "Task",
                "description": "Description",
                "logout": "Log out",
                "tasks-page-title": "Select task to work on",
                "auto-task-chat-message": "I want to work on the task",
                "development-area-page-title": "Select focus area",
                "focus-area-quick-select": "Quick select",
                "focus-area-start-chat": "I want to start coaching within the focus area",
                "focus-area-start-chat-button": "Start coaching session",
                "focus-area-learn-more-button": "I want to learn more about the topic",
                "chat-nav-title": "Chat",
                "tasks-nav-title": "My tasks",
                "ongoing": "Ongoing",
                "completed": "Completed",
                "completed-tasks": "Completed tasks",
                "development-plan-nav-title": "My development plan",
                "focus-area-nav-title": "Focus Area",
                "no-tasks": "You have not created any tasks.",
                "no-development-areas": "You have not created any focus areas.",
                "chat-welcome": "Hi, nice to meet you here!\nI'm your AI coach Emma, let's start with a short introduction.",
                "support-nav-title": "Support",
                "support-page-title": "What can we help you with?",
                "support-description-placeholder": "Describe your issue here...",
                "support-email-info": "You can also email us at",
                "support-modal-success-title": "Thank you for reaching out to us! ",
                "support-modal-success-description": "We will get back to you as soon as possible.",
                "support-error-message": "An error occurred, please try again later.",
                "about-nav-title": "About Zebrain",
                "feedback-nav-title": "Feedback",
                "feedback-title": "Feedback",
                "menu-feedback": "Feedback",
                "request-feedback": "Request feedback",
                "received-feedback": "Received feedback",
                "choose-feedback": "Choose what to get feedback on",
                "create-feedback": "Create feedback",
                "feedback-create-review-questions": "Create review questions",
                "feedback-select-recipients": "Select recipients",
                "feedback-recipients": "Recipients",
                "feedback-recipients-add": "Add recipients",
                "feedback-results": "Feedback results",
                "other-subject": "Other subject",
                "create-review-question": "Create review question",
                "feedback-create-summary": "Summary",
                "feedback-regenerate-question": "Regenerate question",
                "feedback-input-placeholder": "Write text here...",
                "feedback-add-question": "Add question",
                "select-all": "Select all",
                "send-request": "Send request",
                "feedback-change-question": "Describe how you would like to change the question",
                "feedback-review-questions": "Review questions",
                "feedback-request-sent": "Request sent",
                "feedback-summary-modal-body": "Your feedback request is sent. You will get a notification when you recieve feedback on Feedback page.",
                "feedback-recipients-alert": "Select at least 1 recipient",
                "feedback-recipients-summary-alert": "Go back to select at least 1 recipients",
                "feedback-create-question-alert": "Go back to create at least 1 review question",
                "feedback-is-anonymous": "All responses remain anonymous.",
                "received": "Received",
                "feedback-out-of": "feedback out of",
                "feedback-number-of-feedbacks": "recipients have submitted feedback.",
                "feedback-time-left": "The deadline for submissions is",
                "feedback-sent": "Feedback sent",
                "feedback-selected-recipients": "Selected recipients",
                "feedback-no-selected-recipients": "No selected recipients",
                "feedback-response-form-alert": "Please fill in all fields",
                "feedback-recipientpage-questions-alert": "It looks like you've deleted all the review questions. Before you can select recipients, you'll need to create new questions.",
                "requested-from": "Requested from",
                "already-submitted": "Already submitted",
                "already-submitted-message": "You have already submitted feedback",
                "thank-you-for-your-feedback": "Thanks for taking your time!",
                "back-to-tasks": "Back to My tasks",
                "question": "Question",
                "created-at": "Created",
                "respondents": "Respondents",
                "your-feedback": "Received feedback",
                "asking-for-feedback": "is asking for your feedback",
                "strongly-agree": "Strongly agree",
                "agree": "Somewhat agree",
                "neutral": "Neither agree nor disagree",
                "disagree": "Somewhat disagree",
                "strongly-disagree": "Strongly disagree",
                "selected": "Selected",
                "no-selected-recipients": "You haven't selected any recipients",
                "no-available-recipients": "No available recipients",
                "feedback-request-sent-description": "Your feedback request is sent. Received feedback will appear on the Feedback page.",

                // ERROR
                "deadline-passed": "The deadline has passed",
                "deadline-passed-message": "Sorry, this feedback request is already closed and we are no longer accepting responses. If you have any questions, please contact",
            },
        },
        sv: {
            translation: {
                "home": "Hem",
                "all": "Alla",
                "start": "Start",
                "invalid-email": "Ogiltig e-postadress. Ange en korrekt e-postadress",
                "next": "Nästa",
                'deadline': 'Slutdatum',
                "continue": "Fortsätt",
                "send": "Skicka",
                "submit": "Skicka",
                "search-email": "Sök e-postadress",
                "time-ago-now": "nu",
                "chat-placeholder": "Skriv ditt meddelande här...",
                "chat-send": "Skicka",
                "chat-start": "Kom igång",
                "chat": "Chatt",
                "menu-chat": "Chatta med Emma",
                "menu-my-tasks": "Mina uppgifter",
                "menu-development-plan": "Min utvecklingsplan",
                "menu-my-account": "Mitt konto",
                "menu-support": "Support",
                "menu-about-company": "Om Zebrain",
                "task": "Uppgift",
                "description": "Beskrivning",
                "logout": "Logga ut",
                "tasks-page-title": "Välj uppgift att arbeta med",
                "auto-task-chat-message": "Jag vill arbeta med uppgiften",
                "development-area-page-title": "Välj fokusområde",
                "focus-area-quick-select": "Snabbval",
                "focus-area-start-chat": "Jag vill starta coaching inom fokusområdet",
                "focus-area-start-chat-button": "Starta coachsession",
                "focus-area-learn-more-button": "Jag vill lära mig mer om ämnet",
                "chat-nav-title": "Chatt",
                "tasks-nav-title": "Mina uppgifter",
                "ongoing": "Pågående",
                "completed": "Färdiga",
                "completed-tasks": "Färdiga uppgifter",
                "development-plan-nav-title": "Min utvecklingsplan",
                "focus-area-nav-title": "Fokusområde",
                "no-tasks": "Du har inte skapat några uppgifter.",
                "no-development-areas": "Du har inte skapat några fokusområden.",
                "chat-welcome": "Hej, vad kul att träffa dig här!\nJag är din AI coach Emma, låt oss börja med en kort introduktion.",
                "support-nav-title": "Support",
                "support-page-title": "Vad kan vi hjälpa dig med?",
                "support-description-placeholder": "Beskriv ditt problem här...",
                "support-email-info": "Du kan också mejla oss på",
                "support-modal-success-title": "Tack för att du kontaktar oss! ",
                "support-modal-success-description": "Vi återkommer så snart som möjligt.",
                "support-error-message": "Ett fel uppstod, försök igen senare.",
                "about-nav-title": "Om Zebrain",
                "menu-feedback": "Feedback",
                "feedback-nav-title": "Feedback",
                "feedback-title": "Feedback",
                "request-feedback": "Begär återkoppling",
                "received-feedback": "Mottagen återkoppling",
                "create-feedback": "Skapa återkoppling",
                "feedback-create-review-questions": "Skapa feedbackfrågor",
                "feedback-select-recipients": "Välj mottagare",
                "feedback-recipients": "Mottagare",
                "feedback-recipients-add": "Lägg till mottagare",
                "feedback-results": "Återkopplingsresultat",
                "choose-feedback": "Välj vad du vill få återkoppling på",
                "other-subject": "Övrigt ämne",
                "create-review-question": "Skapa återkopplingsfråga",
                "feedback-create-summary": "Sammanfattning",
                "feedback-regenerate-question": "Ändra fråga",
                "feedback-input-placeholder": "Beskriv din ändring...",
                "feedback-add-question": "Lägg till fråga",
                "select-all": "Välj alla",
                "send-request": "Skicka förfrågan",
                "feedback-change-question": "Beskriv hur du skulle vilja ändra frågan",
                "feedback-review-questions": "Feedbackfrågor",
                "feedback-request-sent": "Förfrågan skickad",
                "feedback-summary-modal-body": "Din återkopplingsförfrågan är skickad. Du kommer få en notifikation när du får återkoppling på Återkopplingssidan.",
                "feedback-recipients-alert": "Välj minst 1 mottagare",
                "feedback-recipients-summary-alert": "Gå tillbaka och välj minst 1 mottagare",
                "feedback-create-question-alert": "Gå tillbaka och skapa minst en feedbackfråga",
                "feedback-is-anonymous": "Alla svar förblir anonyma.",
                "feedback-number-of-feedbacks": "mottagare har lämnat feedback.",
                "feedback-time-left": "Sista datum för inlämning är den",
                "feedback-sent": "Feedback skickad",
                "feedback-selected-recipients": "Valda mottagare",
                "feedback-no-selected-recipients": "Inga valda mottagare",
                "feedback-response-form-alert": "Vänligen fyll i alla fält",
                "feedback-recipientpage-questions-alert": "Det verkar som att du har raderat alla feedbackfrågor. Innan du kan välja mottagare behöver du skapa nya frågor.",
                "requested-from": "Förfrågan till",
                "already-submitted": "Redan skickat",
                "already-submitted-message": "Du har redan skickat feedback",
                "thank-you-for-your-feedback": "Tack för att du tog dig tid!",
                "received": "Mottagen",
                "feedback-out-of": "återkopplingar av",
                "back-to-tasks": "Tillbaka till Mina uppgifter",
                "question": "Fråga",
                "created-at": "Skapad",
                "respondents": "Deltagare",
                "your-feedback": "Mottagen feedback",
                "asking-for-feedback": "ber om din feedback",
                "strongly-agree": "Håller helt med",
                "agree": "Håller delvis med",
                "neutral": "Varken eller",
                "disagree": "Håller inte med",
                "strongly-disagree": "Håller inte alls med",
                "selected": "Valda",
                "no-selected-recipients": "Du har inte valt några mottagare",
                "no-available-recipients": "Inga tillgängliga mottagare",
                "feedback-request-sent-description": "Din feedbackförfrågan har skickats. Mottagen feedback kommer synas på Feedbacksidan.",
                // ERROR
                "deadline-passed": "Deadline har passerat",
                "deadline-passed-message": "Tyvärr är denna feedbackförfrågan redan stängd och vi tar inte längre emot svar. Om du har några frågor, vänligen kontakta",
            },
        },
    },
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;