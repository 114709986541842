import { useEffect, useRef } from "react";

type UseAutoScrollProps = {
    messages: any[];
    containerRef: React.RefObject<HTMLDivElement>;
    setIsAtBottom: (isAtBottom: boolean) => void;
    setShowScrollButton: (showScrollButton: boolean) => void;
};

const useAutoScroll = ({ messages, containerRef, setIsAtBottom, setShowScrollButton }: UseAutoScrollProps) => {
    const atBottomRef = useRef(true);

    // Handle scroll position detection
    useEffect(() => {
        if (!containerRef.current) return;

        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current!;
            const nearBottom = scrollHeight - scrollTop - clientHeight < 2;
            atBottomRef.current = nearBottom;
            setIsAtBottom(nearBottom);
        };

        const currentContainer = containerRef.current;
        currentContainer.addEventListener('scroll', handleScroll);
        // Initialize the scroll position
        handleScroll();

        return () => currentContainer.removeEventListener('scroll', handleScroll);
    }, [containerRef.current, setIsAtBottom]);

    useEffect(() => {   
        if (!containerRef.current) return;
        const handleScroll = () => {
            setShowScrollButton(isScrolledOneContainerHeightFromBottom());
        };

        const currentContainer = containerRef.current;
        currentContainer.addEventListener('scroll', handleScroll);
        // Initialize the scroll position
        handleScroll();

        return () => currentContainer.removeEventListener('scroll', handleScroll);
    }, [containerRef.current, setShowScrollButton]);

    // Auto-scroll when new messages arrive
    useEffect(() => {
        if (atBottomRef.current && containerRef.current) {
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    }, [messages, containerRef]);



    // Expose a manual scroll function
    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    };

    const isScrolledOneContainerHeightFromBottom = () => {
        if (!containerRef.current) return false;
    
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        const distanceFromBottom = scrollHeight - scrollTop - clientHeight;
        
        return distanceFromBottom <= clientHeight;
      };

    return { scrollToBottom, isScrolledOneContainerHeightFromBottom };
};

export { useAutoScroll };
