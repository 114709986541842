
import React from 'react';

type TextElement = 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'a' | 'label' | 'div' | 'li' | 'ul' | 'ol' | 'label';

const textColors = {
  black: 'text-gray-900',
  white: 'text-white',
  gray: 'text-neutral-500',
  red: 'text-red-500',
  yellow: 'text-yellow-500',
  green: 'text-green-500',
  blue: 'text-blue-500',
  indigo: 'text-indigo-500',
  purple: 'text-purple-500',
  pink: 'text-pink-500',
};

// Define sizes
const sizeClasses = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
};

const weightClasses = {
  normal: 'font-normal',
  medium: 'font-medium',
  bold: 'font-bold',
  semibold: 'font-semibold',
  extraBold: 'font-extrabold',
};

const presetClasses = {
  'button': 'text-sm font-medium text-neutral-900',
};
interface TextProps {
  element?: TextElement;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color?: keyof typeof textColors;
  className?: string;
  href?: string;
  children: React.ReactNode;
  weight?: 'normal' | 'bold' | 'semibold' | 'extraBold';
  preset?: 'button' | 'label' | 'input';
}

const TextComponent: React.FC<TextProps> = ({
  element = 'p',
  size = 'md',
  color = 'black',
  weight = 'normal',
  className = '',
  preset = "",
  href,
  children,
  otherProps = {}
}) => {
  let combinedClasses = '';
  if (preset !== "") {
    combinedClasses = `${presetClasses[preset]} ${className}`
  } else {
    // Combined Tailwind classes
    combinedClasses = `${sizeClasses[size]} ${textColors[color]} ${weightClasses[weight]} ${className}`;
  }

  // Element mapping
  const Element = element;

  if (element === 'a' && href) {
    return (
      <Element className={combinedClasses} href={href} {...otherProps}>
        {children}
      </Element>
    );
  }

  return <Element className={combinedClasses} {...otherProps}>{children}</Element>;
};

export default TextComponent;