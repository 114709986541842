import React from 'react';

interface RadioButtonProps {
  /** Unique identifier for the radio input */
  id: string;
  /** Name attribute for the radio group */
  name: string;
  /** Value of the radio option */
  value: string;
  /** Whether the radio option is selected */
  checked: boolean;
  /** Callback when the radio option is selected */
  onChange: () => void;
  /** Label text for the radio option */
  label: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  name,
  value,
  checked,
  onChange,
  label,
}) => {
  return (
    <label htmlFor={id} className="flex items-center cursor-pointer">
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="hidden"
      />
      <div
        className={`
          w-6 h-6
          rounded-full
          border
          flex-shrink-0
          relative
          cursor-pointer
          ${checked ? 'border-primary-500' : 'border-neutral-400'}
          after:absolute after:inset-1
          after:rounded-full
          after:bg-primary-500
          after:ring-primary-500
          ${checked ? '' : 'after:hidden'}
        `}
      ></div>
      <span className="ml-2 text-sm text-white font-medium">
        {label}
      </span>
    </label>
  );
};

export default RadioButton;
