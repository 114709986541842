import React from 'react';
import checkmarkIcon from 'shared-assets/icons/checkmark.svg';

interface CheckboxProps {
  id: string;
  label: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  checked = false,
  onChange,
  disabled = false,
  className = '',
  inputClassName = '',
  labelClassName = '',
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  return (
    <label
      htmlFor={id}
      className={`flex items-center ${
        disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
      } ${className} ${labelClassName}`}
    >
      <div className="relative">
        <input
          type="checkbox"
          id={id}
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
          className={`absolute h-6 w-6 opacity-0 cursor-pointer ${inputClassName} peer`}
        />
        <div
          className={`h-6 w-6 rounded flex items-center justify-center cursor-pointer ${
            checked ? 'bg-primary-500' : 'border border-neutral-400 bg-transparent'
          }`}
        >
          <img
            src={checkmarkIcon}
            alt="Checked"
            className={`w-5 h-5 ${checked ? 'block' : 'hidden'}`}
          />
        </div>
      </div>
      <span className="ms-2 text-base font-normal text-white">{label}</span>
    </label>
  );
};

export default Checkbox;
