
import React, { useEffect, useState, useRef } from 'react';
import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { questionnaireAtom } from '../../jotai_state';
import { useAuth0 } from '@auth0/auth0-react';

import closeIcon from 'shared-assets/icons/close.svg';
import alertIcon from 'shared-assets/icons/red/alert.svg';

import {CustomButton, Badge} from 'shared-components';
import QuestionCard from '../CreateFeedbackPage/components/QuestionCard';
import Modal from './components/SuccessModal';

const emmaIcon = new URL('/public/emma-avatar.png', import.meta.url).toString();

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const CreateFeedbackSummaryPage = () => {
    const { getAccessTokenSilently, logout: auth0logout } = useAuth0();
    const translation = useTranslation();
    const { t } = translation;

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const [questionnaire, setQuestionnaire] = useAtom(questionnaireAtom);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const answerOptions = [
        t("strongly-disagree"),
        t("disagree"),
        t("neutral"),
        t("agree"),
        t("strongly-agree"),
    ];

    useEffect(() => {
        if (hasSubmitted) {
            // Make request to backend
            setIsModalOpen(true);
            // Clear questionnaire
            setQuestionnaire((prev) => ({
                ...prev,
                cleanState: true,
            }));
        }
    }, [hasSubmitted]);

    const removeQuestion = (id) => {
        setQuestionnaire({
            ...questionnaire,
            structure: questionnaire.structure.filter((question) => question.id !== id),
        });
    }

    const removeRecipient = (email) => {
        setQuestionnaire({
            ...questionnaire,
            recipients: questionnaire.recipients.filter((recipient) => recipient.email !== email),
        });
    }

    const sendFeedbackRequest = async () => {
        // Go to next page if at least one question has been created and is not loading
        const body = JSON.stringify({
            origin: {
                id: questionnaire.origin.id,
                type: questionnaire.origin.type,
                content: questionnaire.origin.content,
                title: questionnaire.origin.title,
            },
            structure: questionnaire.structure,
            feedback_users: questionnaire.recipients.map((recipient) => recipient.email),
            deadline: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
        })

        console.log("Body:", body);

        if (questionnaire.structure.length > 0 && questionnaire.recipients.length > 0) {
            try {
                let token = await getAccessTokenSilently();
                const response = await fetch('https://feedback-api.dazzle.zebrain.se/questionnaire', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: body,
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const questionData = await response.json();
                console.log("Question data:", questionData);
                setHasSubmitted(true);
            } catch (error) {
                console.error('Failed to fetch user data', error);
            }

        }
    }
    console.log("Questionnaire:", questionnaire);
    return (
        <div className="flex flex-col h-full flex-grow min-h-0 gap-6 pr-2">
            <h1 className="text-2xl font-medium dark:text-white">
                {t("feedback-create-summary")}
            </h1>

            <div className="flex flex-col gap-6">
                {/* Questions */}
                <div >
                    <h2 className="text-lg font-medium dark:text-white mb-3">
                        {t("feedback-review-questions")}
                    </h2>
                    <div className="gap-3 flex flex-col pb-1 px-[1px]">
                        {questionnaire.structure.length > 0 ? (
                            questionnaire.structure.map((question, index) => {
                                return (
                                    <div key={`summary-question-card-${question.id}`}className="flex flex-col gap-6" >

                                        <QuestionCard
                                            
                                            question={question}
                                            removeQuestion={() => removeQuestion(question.id)}
                                            answerOptions={answerOptions}
                                        />
                                    </div>
                                );
                            })

                        ) : (
                            <div className="flex items-center rounded-md border border-cherry-500 bg-cherry-200 text-neutral-900 p-4 gap-2">
                                <img src={alertIcon} alt="Alert" className="w-6 h-6" />
                                <span>{t('feedback-create-question-alert')}</span>
                            </div>
                        )}
                    </div>
                </div>

                {/* Recipients */}
                <div>
                    <h2 className="text-lg font-medium dark:text-white mb-3">
                        {t("feedback-recipients")}
                    </h2>
                    {questionnaire.recipients.length > 0 ? (<div className="gap-3 flex flex-col pb-1 px-[1px]">
                        {questionnaire.recipients.map((recipient, index) => {
                            return (
                                <div>
                                    <Badge
                                        key={index}
                                        rightIcon={closeIcon}
                                        onRightIconClick={() => removeRecipient(recipient.email)}
                                        label={recipient.name || recipient.email}
                                    />
                                </div>
                            )
                        })}
                    </div>) : (
                        <div className="flex items-center rounded-md border border-cherry-500 bg-cherry-200 text-neutral-900 p-4 gap-2">
                            <img src={alertIcon} alt="Alert" className="w-6 h-6" />
                            <span>{t('feedback-recipients-summary-alert')}</span>
                        </div>
                    )
                    }
                </div>
            </div>

            {/* Buttons */}
            <CustomButton
                onClick={() => sendFeedbackRequest()}
                extraStyle="w-full"
                disabled={(questionnaire.structure.length === 0 || questionnaire.recipients.length < 1)}
            >
                {t("send-request")}
            </CustomButton>
            <Modal
                isOpen={isModalOpen}
                onClose={() => { navigate("/tasks") }}
            />
        </div>
    );
}

export default CreateFeedbackSummaryPage;