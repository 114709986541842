import React, { createContext, useState } from 'react';

export const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
    const [showNavigation, setShowNavigation] = useState(false);

    return (
        <NavigationContext.Provider value={{ showNavigation, setShowNavigation }}>
            {children}
        </NavigationContext.Provider>
    );
};
