
import React, { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { questionnaireAtom } from '../../jotai_state';
import { useData } from '../../contexts/dataContext';
import { CustomButton, Input } from 'shared-components';
import QuestionCard from './components/QuestionCard';

import sendIcon from 'shared-assets/icons/send.svg';
import plusIcon from 'shared-assets/icons/plus.svg';
const emmaIcon = new URL('/public/emma-avatar.png', import.meta.url).toString();

import { Outlet, useLocation, useNavigate } from 'react-router-dom';


const CreateFeedbackPage = () => {
    const { getAccessTokenSilently, logout: auth0logout } = useAuth0();
    const { client_id } = useData();
    const translation = useTranslation();
    const { t } = translation;

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const feedbackSourceId = queryParams.get('id');
    const feedbackSourceContent = queryParams.get('content');
    const feedbackSourceTitle = queryParams.get('title');
    const feedbackType = queryParams.get('type');

    const [questionnaire, setQuestionnaire] = useAtom(questionnaireAtom);
    const [isFirstRender, setIsFirstRender] = useState(true);

    const [inputValueMap, setInputValueMap] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const scrollableDivRef = useRef(null);

    const answerOptions = [
        t("strongly-disagree"),
        t("disagree"),
        t("neutral"),
        t("agree"),
        t("strongly-agree"),
    ];

    useEffect(() => {
            if(isFirstRender) {
                setIsFirstRender(false);
            } else {
                const hasParams = !!feedbackType && !!feedbackSourceId;
                const isEmpty = questionnaire.structure.length === 0;
                const isSameOrigin = feedbackType === questionnaire.origin.type && feedbackSourceContent === questionnaire.origin.content;
                const shouldCleanState = questionnaire.cleanState;
                console.log("Has params:", hasParams);
                console.log("Is empty:", isEmpty);
                console.log("Is same origin:", isSameOrigin);
                console.log("Should clean state:", shouldCleanState);


                if (hasParams && (isEmpty || !isSameOrigin || shouldCleanState)) {
                    const origin = {
                        id: feedbackSourceId,
                        type: feedbackType,
                        content: feedbackSourceContent,
                        title: feedbackSourceTitle,
                    };
                    generateInitialQuestionnaire(origin);
                } else if (isEmpty && !!questionnaire.origin.type) {
                    // If the questionnaire is empty, generate a new question
                    regenerateQuestions(questionnaire.origin, questionnaire.recipients);
                } else if ((!feedbackType || !feedbackSourceId) && questionnaire.structure.length === 0) {
                    navigate('/tasks');
                }
                setIsLoading(false);
            }
    }, [isFirstRender, questionnaire.structure, location]);

    useEffect(() => {
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
        }
    }, [questionnaire.structure]);


    const createNewQuestionBattery = async (origin) => {

        let token = await getAccessTokenSilently();
        try {

            const response = await fetch('https://feedback-api.dazzle.zebrain.se/questionnaire/generate_questionnaire', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    client_id: client_id,
                    origin: origin,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }

            const questionData = await response.json();
            console.log("Question data:", questionData);
            return questionData;
        } catch (error) {
            // Clear the questionnaire if an error occurs
            console.error('Failed to fetch user data', error);
        }
    };

    const generateInitialQuestionnaire = async (origin) => {
        console.log("Generating new question");

        // Create a placeholder question with isLoading: true
        const placeholderQuestion = {
            id: -1,
            isLoading: true,
            description: "",
            type: "",
        };

        // Add the placeholder question to the questionnaire
        setQuestionnaire((prev) => ({
            recipients: [],
            structure: [placeholderQuestion],
            origin: origin,
            id: feedbackSourceId,
            cleanState: false,
        }));

        try {
            const newQuestionsList = await createNewQuestionBattery(origin);
    
            setQuestionnaire((prev) => ({
                ...prev,
                origin: origin,
                structure: newQuestionsList.map((question, index) => ({
                    ...question,
                    isLoading: false,
                    id: index,
                })),
                recipients: [],
            }));
        } catch (error) {
            console.error('Failed to fetch user data', error);
        }
    };

    const regenerateQuestions = async (origin, recipients) => {

        console.log("Generating new question");

        // Create a placeholder question with isLoading: true
        const placeholderQuestion = {
            id: -1,
            isLoading: true,
            description: "",
            type: "",
        };

        // Add the placeholder question to the questionnaire
        setQuestionnaire((prev) => ({
            recipients: questionnaire.recipients || [],
            structure: [placeholderQuestion],
            origin: origin,
            id: feedbackSourceId,
            cleanState: false,
        }));

        try {
            const newQuestionsList = await createNewQuestionBattery(origin);

            setQuestionnaire((prev) => ({
                ...prev,
                origin: origin,
                structure: newQuestionsList.map((question, index) => ({
                    ...question,
                    isLoading: false,
                    id: index,
                })),
                recipients: recipients || [],
            }));
        } catch (error) {
            console.error('Failed to fetch user data', error);
        }
    }

    const generateOneNewQuestion = async () => {
        // Create a placeholder question with isLoading: true
        const placeholderQuestion = {
            id: -1,
            isLoading: true,
            description: "",
            type: "",
        };

        // Add the placeholder question to the questionnaire
        setQuestionnaire((prev) => ({
            ...prev,
            structure: [...prev.structure, placeholderQuestion]
        }));

        let token = await getAccessTokenSilently();
        try {
            const structure = questionnaire.structure.map((question) => {
                return {
                    type: "AgreementScale",
                    description: question.description,
                };
            });
            const response = await fetch('https://feedback-api.dazzle.zebrain.se/questionnaire/add_question', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    origin: {
                        id: feedbackSourceId,
                        type: feedbackType,
                        content: feedbackSourceContent,
                        title: feedbackSourceTitle,
                    },
                    structure: structure
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }

            const questionData = await response.json();
            console.log("Question data:", questionData);
            questionData.id = questionnaire.structure.length;
            questionData.isLoading = false;

            let newStructure = [...questionnaire.structure, questionData];

            // Update the questionnaire state by replacing the placeholder with the new questions
            setQuestionnaire((prev) => ({
                ...prev,
                structure: newStructure,
            }));

        } catch (error) {
            console.error('Failed to fetch user data', error);
        }
    }

    const updateQuestion = async (id) => {
        const value = inputValueMap[id];
        console.log(`Updating question with id ${id} with value: ${value}`);
        const question = questionnaire.structure.find((question) => question.id === id);
        const structure = questionnaire.structure.map((question) => {
            return {
                type: "AgreementScale",
                description: question.description,
            };
        });

        const origin = {
            id: feedbackSourceId,
            type: feedbackType,
            content: feedbackSourceContent,
            title: feedbackSourceTitle,
        };

        // Set the question with the given id to loading
        setQuestionnaire((prev) => ({
            ...prev,
            structure: prev.structure.map((question) =>
                question.id === id ? { ...question, isLoading: true } : question
            ),
        }));

        let token = await getAccessTokenSilently();
        try {
            const response = await fetch('https://feedback-api.dazzle.zebrain.se/questionnaire/regenerate_question', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    origin: origin,
                    structure: structure,
                    question_to_regenerate: question.description,
                    prompt: value,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }

            const questionData = await response.json();

            const updatedQuestion = {
                ...question,
                description: questionData.description,
                isLoading: false,
            };

            console.log("Question data:", questionData);
            console.log("Updated question:", updatedQuestion);

            // Update the questionnaire state by replacing the old question with the updated one
            setQuestionnaire((prev) => ({
                ...prev,
                structure: prev.structure.map((q) =>
                    q.id === id ? updatedQuestion : q
                ),
            }));

        } catch (error) {
            console.error('Failed to fetch user data', error);
        }


    };

    const LoadingAnimation = () => {
        return (
            <div className="flex items-center justify-center">
                <div className="relative">
                    {/* Circle Container */}
                    <div className="w-24 h-24 rounded-full flex items-center justify-center bg-neutral-700">
                        {/* Dots */}
                        <div className="flex space-x-2">
                            <div className="w-[8px] h-[8px] bg-primary-500 rounded-full animate-dot [animation-delay:0s]"></div>
                            <div className="w-[8px] h-[8px] bg-primary-500 rounded-full animate-dot [animation-delay:0.2s]"></div>
                            <div className="w-[8px] h-[8px] bg-primary-500 rounded-full animate-dot [animation-delay:0.4s]"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const goToNextPage = () => {
        // Go to next page if at least one question has been created and is not loading
        if (questionnaire.structure.length > 0) {
            const isLoading = questionnaire.structure.some((question) => question.isLoading);
            if (!isLoading) {
                navigate(`/tasks/feedback/create/recipients`);
            }
        }
    }

    const removeQuestion = (id) => {
        setQuestionnaire({
            ...questionnaire,
            structure: questionnaire.structure.filter((question) => question.id !== id),
        });
    }

    if (!questionnaire || isLoading) {
        return null;
    }

    return (
        <div className="flex flex-col h-full flex-grow min-h-0 gap-6">
            <h1 className="text-2xl font-medium dark:text-white">
                {t("feedback-create-review-questions")}
            </h1>

            {/* Question cards */}
            <div ref={scrollableDivRef} className="flex flex-col pb-1 pr-2">
                {/* Question cards */}
                {questionnaire.structure.length === 0 && (
                    <div className="flex flex-col gap-4 bg-neutral-800 rounded-md p-4">
                        <div className="flex justify-center">
                            <LoadingAnimation />
                        </div>
                    </div>
                )}
                {questionnaire.structure.map((question, index) => {
                    if (question.isLoading) {
                        return (
                            <>
                                <div
                                    key={String(question.id)}
                                    className="flex flex-col gap-4 bg-neutral-800 rounded-md p-4"
                                >
                                    <div className="flex justify-center">
                                        <LoadingAnimation />
                                    </div>
                                </div>

                                {index !== questionnaire.structure.length - 1 && <hr className="border-neutral-700 my-6" />}
                            </>
                        );
                    }
                    return (
                        <>
                            <div className="flex flex-col gap-6" key={`create-question-card-${question.id}`}>

                                <QuestionCard
                                    
                                    question={question}
                                    removeQuestion={() => removeQuestion(question.id)}
                                    answerOptions={answerOptions}
                                />

                                <div className="mx-[1px] flex flex-col gap-2">
                                    <h3 className="font-medium text-white">
                                        {t("feedback-regenerate-question")}
                                    </h3>

                                    <div>
                                        <Input
                                            key={`update-question-${index}`}
                                            placeholder={t("feedback-input-placeholder")}
                                            onSubmit={(event) => {
                                                if (event.key === 'Enter') {
                                                    updateQuestion(question.id)
                                                }
                                            }}
                                            onChange={(value) => {
                                                setInputValueMap((prev) => ({
                                                    ...prev,
                                                    [question.id]: value,
                                                }));
                                            }}
                                            extraStyle="w-full"
                                            leftIcon={<img src={emmaIcon} alt="Emma" className="w-6 h-6" />}
                                            rightIcon={<img
                                                src={sendIcon}
                                                alt="Send"
                                                className="w-6 h-6 cursor-pointer"
                                                onClick={() => updateQuestion(question.id)}
                                            />}

                                        />
                                    </div>
                                    <p className="text-sm text-neutral-400">{t("feedback-change-question")}</p>
                                </div>
                            </div>

                            {index !== questionnaire.structure.length - 1 && <hr className="border-neutral-700 my-6" />}
                        </>
                    );
                })}
            </div>

            {/* Buttons */}
            <div className="flex gap-2 flex-col mt-6">
                {/* Buttons */}
                <CustomButton
                    buttonType="outlineWhiteBase"
                    onClick={generateOneNewQuestion}
                    extraStyle="w-full"
                    extraProps={{ disabled: false }}
                    leftIcon={<img src={plusIcon} alt="Add" />}
                >
                    {t("feedback-add-question")}
                </CustomButton>
                <CustomButton
                    onClick={() => goToNextPage()}
                    extraStyle="w-full"
                    extraProps={{ disabled: false }}
                >
                    {t("next")}
                </CustomButton>
            </div>
        </div>
    );

}

export default CreateFeedbackPage;