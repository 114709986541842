
// Card.tsx
import React from 'react';

interface CardProps {
  children: React.ReactNode;
  className?: string;
  onClick?: (ev) => any;
}

const CustomCard = ({ children, className = '', onClick }: CardProps) => (
  <div className={`flex flex-col bg-neutral-800 rounded-md border border-transparent ${className}`} onClick={onClick}>
    {children}
  </div>
);

export default CustomCard;