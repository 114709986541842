// ProtectedRoute.tsx
import React, { useEffect, useContext, useState } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';

import '../../lang/i18n';
import { useTranslation } from 'react-i18next';

import { useData } from '../../contexts/dataContext';
import { useData as uiUseData } from '../../contexts/UIContext';

import DevelopmentCard from './components/DevelopmentCard';

import taskIcon from 'shared-assets/icons/task.svg';
import learningIcon from 'shared-assets/icons/learning.svg';

export default function DevelopmentAreaPage() {
  const [data, setData] = useState([]);

  const { client_id, developmentAreas, fetchDevelopmentAreas } = useData();

  const location = useLocation();
  const navigate = useNavigate();

  const translation = useTranslation();
  const { t, i18n } = translation;
  const { navbarHeight, setNavbarHeight } = uiUseData();

  // check if mobile
  const isMobile = window.innerWidth < 636;


  // Check if the current route is exactly /development-plan
  const isDevelopmentPlanRoot = location.pathname === '/development-plan';

  useEffect(() => {
    fetchDevelopmentAreas()
  }, [client_id]);

  useEffect(() => {
    console.log('navbarHeight', navbarHeight)
  }, [navbarHeight]);

  if (!isDevelopmentPlanRoot) {
    return <Outlet />
  }

  if (!developmentAreas || !developmentAreas.length) {
    return (
      <div className="mt-6">
        <div className="flex justify-center">
          <img src={taskIcon} alt="task icon" className="w-6 h-6 desktop:w-10 desktop:h-10" />
        </div>
        <p className="text-center">
          {t('no-development-areas')}
        </p>
      </div>
    )
  }

  return (
    <>
      <h1 className="text-2xl font-medium dark:text-white  my-4 desktop:my-6">
        {t("development-plan-nav-title")}
      </h1>
      <div>
        <div className="flex flex-col mb-2">
          <h2 className="text-lg font-medium dark:text-white">
            {t('development-area-page-title')}
          </h2>
        </div>
        <div
          className={`gap-2 flex flex-col pr-4`}
          style={{
            paddingBottom: `${isMobile ? navbarHeight + 24 : 0}px`,
          }}
        >
        {developmentAreas.map((area, index) => (
          <DevelopmentCard
            key={`dev-area-card-${index}`}
            leftIcon={learningIcon}
            title={area.title}
            description={area.description}
            onClick={() => navigate(`/development-plan/focus-area/${area.id}`)}
          />
        ))}
        </div>
      </div>
    </>
  )
}