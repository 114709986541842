

import closeIcon from 'shared-assets/icons/close.svg';

const QuestionCard = ({ question, removeQuestion, answerOptions }) => {

    return (
        <div className="flex flex-row gap-4 bg-neutral-800 rounded-md p-4">
            {/* Question info */}
            <div className="flex flex-col gap-4 grow">
                {/* Question description */}
                <div>
                    <p className="text-neutral-400 text-base font-normal">
                        {question.description}
                    </p>
                </div>

                {/* Answer input */}
                <div className="flex flex-col gap-2">
                    {question.type === "AgreementScale" && answerOptions.toReversed().map((option, idx) => (
                        <div className="flex" key={`question-${idx}`}>
                            <ul className="flex gap-2 list-disc list-inside text-neutral-400">
                                <li>{option}</li>
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
            {/* Delete question */}
            <div className="min-w-4">
                <img
                    src={closeIcon}
                    alt="Close"
                    className="cursor-pointer h-4 w-4"
                    onClick={removeQuestion}
                />
            </div>
        </div>
    )
}

export default QuestionCard;